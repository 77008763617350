section a {
    text-decoration: none;

}

section {
  border-bottom: 1px solid #ccc;
  margin-top: 1em;
}

section .col-md-3 {
  border-left: 1px solid #ccc;
}

section .col-md-3:first-child {
  border: none;
}