.main p,
ol,
ul,
li,
a,
tr,
td {
  font-size: 13px;
  font-family: Verdana, Geneva, Tahoma, Arial, Helvetica, sans-serif;
}
.main h1,
.main h2,
.main h3,
.main h4,
.main h5,
.main h6 {
  font-family: Arial, Helvetica, sans-serif;
}

.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.main h1 {
  font-size: 24px;
  line-height: 20px;
}
.main h2 {
  font-size: 18px;
  line-height: 20px;
}
.main h3 {
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
}
.main h4 {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
}
.main h5 {
  font-size: 12px;
  line-height: 20px;
  font-weight: bold;
}
.main h6 {
  font-size: 12px;
  line-height: 20px;
  font-weight: bold;
}

.menu h2 {
  font-size: 18px;
  margin-top: 10px;
}

/*@media screen and (max-width: 768px) {
.main h1 { font-size: 18px; line-height: 20px; }
.main h2 { font-size: 16px; line-height: 20px; }
.main h3 { font-size: 14px; line-height: 20px; }

.menu h2 { font-size:16px; margin-top:10px;}
}*/

.footer {
  margin-top: 20px;
  text-align: center;
  font-size: 11px;
  font-family: Verdana, Geneva, Tahoma, Arial, Helvetica, sans-serif;
}
.footer a {
  color: #444444;
}

.success,
.error {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  border: 1px solid;
  margin: 10px 0px;
  padding: 15px 10px 15px 50px;
  background-repeat: no-repeat;
  background-position: 10px center;
}
.success {
  color: #00529b;
  font-weight: bold;
  background-color: #bde5f8;
  /* background-image:url('../images/success.png'); */
}
.error {
  color: #d8000c;
  font-weight: bold;
  background-color: #ffbaba;
  /* background-image: url('../images/error.png'); */
}

.form-control-inline {
  min-width: 0;
  width: auto;
  display: inline;
}

.danger {
  background-color: #f2cbcf !important;
}

body.darkpagetheme .form-select {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 16 16'><path d='M2 5l6 6 6-6'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
}

body.darkpagetheme {
  background-color: rgb(21, 32, 43);
  color: #ddd !important;
}

body.darkpagetheme select,
body.darkpagetheme select option {
  background-color: #1d2a35 !important;
  color: #ddd !important;
}

body.darkpagetheme mark {
  background-color: transparent;
  color: yellow;
}

body.darkpagetheme #right .row.border {
  background-color: #1d2a35 !important;
  border: 1px solid #5d5f60 !important;
}

body.darkpagetheme .alert-warning {
  color: #ffffff;
  background-color: #0e324c;
  border-color: #666666;
}

body.darkpagetheme .btn-default {
  color: #fff;
  background-color: #181c34 !important;
  border-color: #ccc;
}

body.darkpagetheme .btn-primary {
  background-color: #1a5087 !important;
  border-color: #1a5087 !important;
}

body.darkpagetheme .btn-secondary {
  background-color: #262626 !important;
  border-color: #262626 !important;
}

body.darkpagetheme .btn-warning {
  background-color: #977606 !important;
  border-color: #977606 !important;
}

body.darkpagetheme ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #fff;
}
body.darkpagetheme :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
  opacity: 1;
}
body.darkpagetheme ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
  opacity: 1;
}
body.darkpagetheme :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}
body.darkpagetheme ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
}

body.darkpagetheme ::placeholder {
  /* Most modern browsers support this now. */
  color: #fff;
}
body.darkpagetheme input,
body.darkpagetheme .form-control:focus,
body.darkpagetheme textarea {
  background: #102f4e;
  color: white;
}
body.darkpagetheme ::-webkit-file-upload-button {
  background-color: #334354 !important;
  border-color: #00597a !important;
  color: white;
}
body.darkpagetheme .footer a {
  color: #3a8aff !important;
}

body.darkpagetheme th {
  background-color: rgb(13, 23, 33);
  color: #ddd !important;
  border: 1px solid rgb(187 172 172 / 13%) !important;
}

body.darkpagetheme .card,
body.darkpagetheme .card-body,
body.darkpagetheme tr,
body.darkpagetheme td {
  background-color: #1d2a35 !important;
  color: #ddd !important;
  border: 1px solid rgb(187 172 172 / 13%) !important;
}
body.darkpagetheme .card-header {
  border-bottom: 1px solid rgb(187 172 172 / 13%) !important;
}
.message-bkg {
  background: white;
}

body.darkpagetheme .message-bkg {
  background: #1d2a35;
}

body.darkpagetheme .popover-header {
  background-color: #ddd !important;
  color: black !important;
}

body.darkpagetheme .form-control:disabled,
body.darkpagetheme .form-control[readonly] {
  background-color: #484848 !important;
}
.navbar-dark .navbar-nav .nav-link {
  color: white !important;
}

.required:after {
  content: " *";
  color: red;
}

.form-inputs {
  position: relative;
}
.form-inputs .form-control {
  height: 35px;
}

.form-inputs .form-control:focus {
  box-shadow: none;
  border: 1px solid #ccc;
}

.form-inputs i {
  position: absolute;
  right: 10px;
  top: 10px;
}
.ui-menu.ui-widget {
  z-index: 10000;
}
.fs-8 {
  font-size: 8px;
}
.fs-10 {
  font-size: 10px;
}
.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.error-input {
  border: 1px solid red; /* Change the border color to red or your desired color */
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

/* Add any additional styling for your form groups */
.form-group {
  margin-bottom: 15px;
}

.form-check-input {
  border: var(--bs-border-width) solid #3e3e3e !important;
}

.alert .btn-close {
  padding: 10px !important;
}
#basic-navbar-nav .nav-link {
  font-size: 14px;
  padding: 0px 14px !important;
}
#basic-navbar-nav-editor .nav-link {
  color: white !important;
  font-size: 14px;
  padding: 0px 14px !important;
}
.dropdown-toggle::after {
  margin-left: 0.5em;
}

/* .rbt-menu.dropdown-menu.show{
    top: 35px !important;
    left: -100px !important;
} */

#autocompleteTextbox {
  top: 35px !important;
}

input[type="text"]:read-only {
  background-color: #e9e9e9;
}

.drop-area {
  transition: background-color 0.3s ease;
  border: 2px dashed #ddd;
}

.drop-area.dragging {
  background-color: #f0f0f0; /* Change this to the desired background color */
  border: 2px dashed #666;
}

.drop-area.image-hover {
  background-color: #f0f0f0; /* Change this to the desired background color */
}

.drop-area p {
  margin: 0;
}

.highlightCalendarToday {
  background-color: #ffffcc !important;
}

.w-100px {
  width: 100px;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.disabled-link {
  color: inherit;
  cursor: not-allowed;
  pointer-events: none; /* Prevent clicks */
  text-decoration: none;
}
input[type="checkbox"]:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  color: #aaa;
}
.table-container {
  position: relative;
}

.table-container.blur {
  filter: blur(4px);
  pointer-events: none;
}

.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(
    255,
    255,
    255,
    0.7
  ); /* Optional: adds a semi-transparent white background */
  z-index: 10;
}
.custom-modal .modal-dialog {
  width: 100%; /* Full width of the viewport */
  margin: 0; /* Remove default margins */
  max-width: 100%; /* Ensure it does not exceed viewport width */
  height: 100%; /* Ensure it takes full height */
  max-height: 100%; /* Ensure it does not exceed viewport height */
}

.custom-modal .modal-content {
  width: 100%; /* Full width of the modal dialog */
  height: 100%; /* Full height of the modal dialog */
  overflow: hidden; /* Hide overflow */
}

.custom-modal .modal-body {
  overflow-y: auto; /* Enable vertical scrolling if needed */
  white-space: normal; /* Allow text to wrap */
  word-wrap: break-word; /* Break long words if needed */
  padding: 1rem; /* Adjust padding for spacing */
}

.custom-modal .modal-body pre {
  white-space: pre-wrap; /* Preserve whitespace but wrap text */
  word-wrap: break-word; /* Break long words if needed */
}

@media (min-width: 768px) {
  .custom-shadow-md {
    box-shadow: var(--bs-box-shadow-lg) !important;
  }
}
.ipo-text-notify {
  position: relative;
  color: #0000ff;
}

@media (min-width: 992px) {
  .custom-dropdown .dropdown-menu {
    min-width: 900px; /* Ensures wide dropdown */
  }

  .custom-dropdown .dropdown-menu .row {
    display: flex;
    flex-wrap: nowrap; /* Prevents wrapping on large screens */
  }
}

/* Allow wrapping on smaller screens */
@media (max-width: 991px) {
  .custom-dropdown .dropdown-menu .row {
    flex-wrap: wrap; /* Stacks items on mobile */
  }
}
